import { menus } from "app/core/config/menu"
import { cn } from "app/lib/utils"
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuIndicator,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  NavigationMenuViewport,
} from "@/components/ui/navigation-menu"
interface Props {
  pathname: string
}
export function NavigationContainer({ pathname }: Props) {
  return (
    <NavigationMenu>
      <NavigationMenuList className="flex flex-row justify-center gap-4">
        {menus
          .filter((menu) => !menu.disabledLocation?.includes("header"))
          .map((menu, index) => {
            if (menu.children) {
              return (
                <NavigationMenuItem key={index}>
                  <NavigationMenuTrigger className="px-0">
                    <NavigationMenuLink
                      href={menu.slug}
                      className={cn(
                        "text-foreground hover:text-primary transition-all",
                        (pathname === menu.slug ||
                          (pathname.includes(menu.slug.slice(-1) === "s" ? menu.slug.slice(0, -1) : menu.slug) && menu.slug !== "/")) &&
                          "text-primary",
                      )}
                      aria-label={menu.a11y}
                    >
                      {menu.title}
                    </NavigationMenuLink>
                  </NavigationMenuTrigger>
                  {menu.children && (
                    <NavigationMenuContent className="w-full min-w-48 p-4">
                      {menu.children.map((child, index) => (
                        <NavigationMenuLink
                          key={index}
                          href={child.slug}
                          className={cn(
                            "text-foreground hover:text-primary transition-all flex",
                            (pathname === menu.slug ||
                              (pathname.includes(menu.slug.slice(-1) === "s" ? menu.slug.slice(0, -1) : menu.slug) && menu.slug !== "/")) &&
                              "text-primary",
                          )}
                          aria-label={menu.a11y}
                        >
                          {child.title}
                        </NavigationMenuLink>
                      ))}
                    </NavigationMenuContent>
                  )}
                </NavigationMenuItem>
              )
            }
            return (
              <NavigationMenuItem key={index}>
                <NavigationMenuLink
                  href={menu.slug}
                  className={cn(
                    "text-foreground hover:text-primary transition-all",
                    (pathname === menu.slug ||
                      (pathname.includes(menu.slug.slice(-1) === "s" ? menu.slug.slice(0, -1) : menu.slug) && menu.slug !== "/")) &&
                      "text-primary",
                  )}
                  aria-label={menu.a11y}
                >
                  {menu.title}
                </NavigationMenuLink>
              </NavigationMenuItem>
            )
          })}
      </NavigationMenuList>
    </NavigationMenu>
  )
}
