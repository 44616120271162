import type { TDeviceSize } from "../interfaces/screen-size"

type TService = {
  name: string
  summary: string
  slug: string
  hidden: boolean
}

export const services: TService[] = [
  {
    name: "Ultraformer MPT",
    summary: "ค้นพบความงามในแบบของคุณ ด้วยนวัตกรรมที่เหนือระดับจาก Ultraformer MPT",
    slug: "ultraformer-mpt",
    hidden: false,
  },
  {
    name: "Botox",
    summary: "นวัตกรรมเทคโนโลยีชะลอวัย และลดริ้วรอยด้วย Botox",
    slug: "botox",
    hidden: false,
  },
  {
    name: "Filler and Skin Booster",
    summary: "Soleil Clinic",
    slug: "filler",
    hidden: true,
  },
  {
    name: "Meso Fat",
    summary: "ค้นพบความงามในแบบของคุณ ปรับรูปได้ สลายไขมันด้วย Meso Fat Neobella",
    slug: "meso-fat",
    hidden: false,
  },

  {
    name: "Treatment Aquapure",
    summary: "ค้นพบความงามในแบบของคุณ ด้วยนวัตกรรมในการผลัดเซลล์ผิวอย่างล้ำลึกด้วย Aquapure",
    slug: "treatment",
    hidden: false,
  },
]
